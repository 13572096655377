import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import hotkeys from "hotkeys-js";

export default class extends Controller {
  static targets = [ "quickLookupModal" ]

  connect() {
    if (this.modal) {
      this.modal.dispose()
    }
    this.modal = Modal.getOrCreateInstance(this.quickLookupModalTarget);

    // Bind methods to maintain the `this` context
    this.focusInput = this.focusInput.bind(this);
    this.resetForm = this.resetForm.bind(this);

    // Focus input when modal is shown
    this.quickLookupModalTarget.addEventListener("shown.bs.modal", this.focusInput);

    // Call resetForm when modal is closed
    this.quickLookupModalTarget.addEventListener("hidden.bs.modal", this.resetForm);

    // Add hotkey
    hotkeys("ctrl+l", () => this.toggleModal());
  }

  disconnect() {
    if (this.modal) {
      // Cleanup event listeners
      this.quickLookupModalTarget.removeEventListener("shown.bs.modal", this.focusInput);
      this.quickLookupModalTarget.removeEventListener("hidden.bs.modal", this.resetForm);
      this.modal.dispose();
    }

    hotkeys.unbind();
  }

  clickToOpen(event) {
    event.preventDefault();
    this.toggleModal();
  }

  toggleModal() {
    this.modal.toggle();
  }

  focusInput(event) {
    // Focus on the input inside the modal when shown
    event.target.querySelector(".form-control").focus();
  }

  resetForm(event) {
    // Reset the form when modal is closed
    const form = this.quickLookupModalTarget.querySelector("form");
    if (form) {
      form.reset();
    }
  }
}
