import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  async pinNote(event) {
    event.preventDefault();
  
    const targetElement = event.currentTarget;
    const request = new FetchRequest("patch", targetElement.dataset.url);
  
    try {
      const response = await request.perform();
  
      if (response.ok) {
        // Remove 'pinned' class from all pins and add to the clicked one
        document.querySelectorAll(".note-pin").forEach(element => {
          element.classList.remove("pinned");
        });
        targetElement.classList.add("pinned");
      } else {
        console.error("Failed to pin the note:", response.status, response.statusText);
        alert("Failed to pin the note. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert("An unexpected error occurred. Please check your connection and try again.");
    }
  }
}
