// app/javascript/controllers/pick_list_controller.js
import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['generateButton', 'checkListModal'];

  connect() {
    this.checkListModal = Modal.getOrCreateInstance(this.checkListModalTarget);
  }

  async openCheckListModal(event) {
    event.preventDefault()

    const request = new FetchRequest("get", event.currentTarget.href, { responseKind: "turbo-stream" })
    const response = await request.perform()

    if(response.ok) {
      this.checkListModal.show()
    }
  }

  async submitForm(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const submitButton = form.querySelector('[type="submit"]'); // Find the submit button within the form
    const formData = new FormData(form);

    // Disable the submit button and show loading icon
    const originalButtonText = submitButton.innerHTML;
    submitButton.disabled = true;
    submitButton.innerHTML = "<i class=\"fas fa-spinner fa-spin\"></i> Generating PDF...";

    try {
      // Submit form via fetch request
      const response = await fetch(form.action, {
        method: form.method,
        body: formData
      });

      if (!response.ok) {
        throw new Error('PDF generation failed');
      }

      // Re-enable submit button
      submitButton.disabled = false;
      submitButton.innerHTML = originalButtonText;

      // Open PDF in a new tab
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
      // Handle error scenario, maybe display an error message to the user
    }
  }
}
